import React, { useEffect } from 'react'
import { pageTitle } from '../../../helper'
import PageHeading from '../../PageHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import ServicesFeatures from './ServicesFeatures'
import {withTranslation} from "react-i18next";
import ContactCta from "../../contactCta";
function CommercialAdvertising({t}) {
    pageTitle(t('content.adv.title'));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={t('content.adv.title')}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={t('content.adv.title')}
            />


            <Spacing lg='120' md='50'/>
            <Div className="container">
                <Div className="row align-items-center">
                    <Div className="col-xl-5 col-lg-6">
                        <Div className="cs-radius_15 cs-shine_hover_1">
                            <img src="/images/service_img_4.jpg" alt="Service" className='cs-radius_15 w-100' />
                        </Div>
                        <Spacing lg='0' md='40'/>
                    </Div>
                    <Div className="col-lg-6 offset-xl-1">
                        <h2 className="cs-font_50 cs-m0">{t('content.adv.subtitle')}</h2>
                        <Spacing lg='50' md='30'/>
                        <Div className="row">
                            <Div className="col-lg-6">
                                <ServicesFeatures btnText={t('content.adv.content.title1')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.adv.content.title2')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.adv.content.title3')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.adv.content.title4')} variant='cs-type2'/>
                                <Spacing lg='0' md='10'/>
                            </Div>
                            <Div className="col-lg-6">
                                <ServicesFeatures btnText={t('content.adv.content.title5')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.adv.content.title6')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.adv.content.title7')} variant='cs-type2'/>
                                <Spacing lg='0' md='10'/>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
            <Spacing lg='150' md='80'/>

            <ContactCta />
        </>
    )
}

export default withTranslation('common')(CommercialAdvertising);