import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import {withTranslation} from "react-i18next";

function ContactPage({t}) {
  pageTitle(t('contact.title'));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title={t('contact.title')}
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText={t('contact.title')}
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title={t('contact.text')}
              subtitle={t('contact.subtitle')}
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <Div className="cs-google_map">
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3210.2410156284877!2d39.11662702126983!3d21.626445392631755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3db4d1892ca2b%3A0xf2587a8ebf37c24e!2zU3RhcnR1cCBIdWIgRGlnaXRhbCBsINmF2KzZhdi5INix2YrYp9iv2Kkg2KfZhNij2LnZhdin2YQg2LHZgtmF2Yo!5e0!3m2!1sen!2ssa!4v1675465906606!5m2!1sen!2ssa"
                  allowFullScreen
                  title="Google Map"
              />

            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="30" md="80" />

    </>
  );
}
export default withTranslation('common')(ContactPage);