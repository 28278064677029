import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import './scss/index.scss';
import './i18n';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import i18n from "i18next";


console.log(i18next.language);
ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <App/>
            </I18nextProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

