import React, {useEffect, useState} from 'react';

export default function DropDown({children, toggle}) {
  // Mobile Toggle
  const [mobileToggle, setMobileToggle] = useState(false);
  useEffect(() => {
    setMobileToggle(toggle)
  }, [toggle]);
  const handelMobileToggle = () => {
      setMobileToggle(!mobileToggle)
  }
  return (
    <>
      <span className={mobileToggle?"cs-munu_dropdown_toggle active":"cs-munu_dropdown_toggle"} onClick={handelMobileToggle}></span>
      {children}
    </>
  )
}