import React from 'react'
import { Icon } from '@iconify/react';
import {withTranslation} from "react-i18next";

function ContactInfoWidget({withIcon, title, t}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">

        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="ic:baseline-whatsapp" /></span>:''}
          <a href="https://wa.me/966503509432" target="_blank" rel="noreferrer" style={{direction: "ltr"}}>+966 50 350 9432</a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          <a href="mailto:info@cornerofart.com.sa" >info@cornerofart.com.sa</a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
          <a href="https://goo.gl/maps/6CwDr2FBuMELFMoo8" >{t('footer.contactInfo.address')}</a>
        </li>
      </ul>
    </>
  )
}
export default withTranslation('common')(ContactInfoWidget);