import React, {useEffect} from 'react'
import {pageTitle} from '../../../helper'
import PageHeading from '../../PageHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import ContactCta from "../../contactCta";
import {withTranslation} from "react-i18next";

 function Animation({t}) {
    pageTitle(t('content.animation.title'));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={t('content.animation.title')}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={t('content.animation.title')}
            />


            <Spacing lg='120' md='50'/>
            <Div className="container">
                <Div className="row align-items-center">
                    <Div className="col-xl-5 col-lg-6">
                        <Div className="cs-radius_15 cs-shine_hover_1">
                            <img src="/images/service_img_3.jpg" alt="Service" className='cs-radius_15 w-100'/>
                        </Div>
                        <Spacing lg='0' md='40'/>
                    </Div>
                    <Div className="col-lg-6 offset-xl-1">
                        <h2 className="cs-font_50 cs-m0">{t('content.animation.title')}</h2>
                        <p className="cs-font_20 cs-m0" style={{lineHeight: "3rem"}}>{t('content.animation.subtitle')}</p>
                        <Spacing lg='50' md='30'/>

                    </Div>
                </Div>
            </Div>
            <Spacing lg='150' md='80'/>

            <ContactCta />
        </>
    )
}

export default withTranslation('common')(Animation);