import React, {useEffect} from 'react';
import {pageTitle} from '../../helper';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import ContactCta from "../contactCta";

function AboutPage({t}) {
    pageTitle(t("about.title"));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* Start Page Heading Section */}
            <PageHeading
                title={t("about.title")}
                bgSrc="images/about_hero_bg.jpeg"
                pageLinkText={t("about.title")}
            />
            {/* End Page Heading Section */}

            {/* Start About Section */}
            <Spacing lg="150" md="80"/>
            <Div className="container">
                <Div className="row">
                    <Div className={(i18next.language === 'ar') ? "col-lg-5 offset-xl-2" : "col-xl-5 col-lg-7"}>
                        <SectionHeading
                            title={t("about.subtitle")}
                            subtitle={t("about.title")}
                        >
                            <Spacing lg="30" md="20"/>
                            <p className="cs-m0">
                                {t("about.content")}
                            </p>
                            <Spacing lg="30" md="30"/>
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40"/>
                        </SectionHeading>
                    </Div>
                    <Div className={(i18next.language === 'ar') ? "col-xl-5 col-lg-7" : "col-lg-5 offset-xl-2"}>
                        <img
                            src="/images/about_image_3.jpg"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25"/>
                    </Div>
                    <Div className="col-lg-7">
                        <img
                            src="/images/about_image_1.jpg"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25"/>
                    </Div>
                    <Div className="col-lg-5">
                        <img
                            src="/images/about_image_2.jpg"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25"/>
                    </Div>
                </Div>
            </Div>
            {/* End About Section */}
            <Spacing lg="150" md="80"/>
            <ContactCta />
        </>
    );
}
export default withTranslation('common')(AboutPage);