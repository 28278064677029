import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import Layout from './components/Layout';
import Infographic from "./components/Pages/ServicesDetailsPages/Infographic";
import Documentary from "./components/Pages/ServicesDetailsPages/Documentary";
import Animation from "./components/Pages/ServicesDetailsPages/Animation";
import CommercialAdvertising from "./components/Pages/ServicesDetailsPages/CommercialAdvertising";
import Identity from "./components/Pages/ServicesDetailsPages/Identity";
import CorporateProfile from "./components/Pages/ServicesDetailsPages/CorporateProfile";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import {useEffect} from "react";
function App() {
  useEffect(() => {
    const lang = sessionStorage.getItem('lang') || "en";
    if (lang === "ar") {
      document.documentElement.lang = lang;
        document.documentElement.dir = "rtl";
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />


          <Route path="about" element={<AboutPage />} />

          <Route path="service/infographic" element={<Infographic />} />
          <Route path="service/documentary" element={<Documentary />} />
          <Route path="service/animation" element={<Animation />} />identity
          <Route path="service/commercial-advertising" element={<CommercialAdvertising />} />
          <Route path="service/identity" element={<Identity />} />
          <Route path="service/corporate-profile" element={<CorporateProfile />} />
          {/*<Route path="portfolio/portfolio-details" element={<PortfolioDetailsPage />} />*/}
          <Route path="contact" element={<ContactPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >

        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
