import React, {useEffect} from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'
import {withTranslation} from "react-i18next";

function Footer({t}) {
    const copyrightLinks = [

        {
            title: t('footer.privacy.title'),
            href: '/privacy-policy'
        }]

    const serviceMenu = [
        {
            title: t('footer.menu.menuData.title1'),
            href: '/service/corporate-profile'
        },
        {
            title: t('footer.menu.menuData.title2'),
            href: '/service/identity'
        },
        {
            title: t('footer.menu.menuData.title3'),
            href: '/service/documentary'
        },
        {
            title: t('footer.menu.menuData.title4'),
            href: '/service/infographic'
        },
    ]
    const [year, setYear] = React.useState(0)
    useEffect(() => {
        const getYear = new Date().getFullYear()
        console.log(getYear)
        setYear(getYear)
    }, [])


    return (
        <footer className="cs-fooer">
            <Div className="cs-fooer_main">
                <Div className="container">
                    <Div className="row">
                        <Div className="col-lg-4 col-sm-6">
                            <Div className="cs-footer_item">
                                <TextWidget
                                    logoSrc='/images/logo.svg'
                                    logoAlt='Logo'
                                    text={t('footer.text')}
                                />
                                <SocialWidget/>
                            </Div>
                        </Div>
                        <Div className="col-lg-4 col-sm-6">
                            <Div className="cs-footer_item">
                                <MenuWidget menuItems={serviceMenu} menuHeading={t('footer.menu.title')}/>
                            </Div>
                        </Div>
                        <Div className="col-lg-4 col-sm-6">
                            <Div className="cs-footer_item">
                                <ContactInfoWidget title={t('footer.contactInfo.title')}/>
                            </Div>
                        </Div>

                    </Div>
                </Div>
            </Div>
            <Div className="container">
                <Div className="cs-bottom_footer">
                    <Div className="cs-bottom_footer_left">
                        <Div className="cs-copyright">{t('footer.privacy.copyRight')} {year}.</Div>
                    </Div>
                    <Div className="cs-bottom_footer_right">
                        <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
                    </Div>
                </Div>
            </Div>
        </footer>
    )
}

export default withTranslation('common')(Footer);