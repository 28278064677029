import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link to='https://www.linkedin.com/in/%D8%B9%D9%85%D8%B1-%D8%A8%D9%86-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%B9%D8%B2%D9%8A%D8%B2-37408216b' className="cs-center">
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
      <Link to='https://twitter.com/cornerofart' className="cs-center">
        <Icon icon="fa6-brands:twitter" />               
      </Link>
      <Link to='https://www.youtube.com/@cornerofart6037' className="cs-center">
        <Icon icon="fa6-brands:youtube" />              
      </Link>
      <Link to='https://www.instagram.com/cornerofart' className="cs-center">
        <Icon icon="fa6-brands:instagram" />
      </Link>
    </Div>
  )
}
