import React, {useEffect} from 'react'
import {pageTitle} from '../../../helper'
import PageHeading from '../../PageHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import ServicesFeatures from './ServicesFeatures'
import SectionHeading from "../../SectionHeading";
import IconBox from "../../IconBox";
import ContactCta from "../../contactCta";
import {withTranslation} from "react-i18next";

function Identity({t}) {
    pageTitle(t('content.identity.title'));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={t('content.identity.title')}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={t('content.identity.title')}
            />
            <Spacing lg='145' md='80'/>
            <Div className="container">
                <SectionHeading
                    title={t('content.identity.section.title')}
                    subtitle={t('content.identity.section.subtitle')}
                    variant='cs-style1 text-center'
                />
                <Spacing lg='90' md='45'/>
                <Div className="row">
                    <Div className="col-lg-4">
                        <IconBox
                            icon='/images/icons/service_icon_1.svg'
                            title={t('content.identity.section.box.title1')}
                            subtitle={t('content.identity.section.box.desc1')}
                        />
                        <Spacing lg='30' md='30'/>
                    </Div>
                    <Div className="col-lg-4">
                        <IconBox
                            icon='/images/icons/service_icon_2.svg'
                            title={t('content.identity.section.box.title2')}
                            subtitle={t('content.identity.section.box.desc2')}
                        />
                        <Spacing lg='30' md='30'/>
                    </Div>
                    <Div className="col-lg-4">
                        <IconBox
                            icon='/images/icons/service_icon_3.svg'
                            title={t('content.identity.section.box.title3')}
                            subtitle={t('content.identity.section.box.desc3')}
                        />
                        <Spacing lg='30' md='30'/>
                    </Div>
                </Div>
            </Div>

            <Spacing lg='120' md='50'/>
            <Div className="container">
                <Div className="row align-items-center">
                    <Div className="col-xl-5 col-lg-6">
                        <Div className="cs-radius_15 cs-shine_hover_1">
                            <img src="/images/service_img_5.jpg" alt="Identity design " className='cs-radius_15 w-100'/>
                        </Div>
                        <Spacing lg='0' md='40'/>
                    </Div>
                    <Div className="col-lg-6 offset-xl-1">
                        <h2 className="cs-font_50 cs-m0">{t('content.identity.content.title')}</h2>
                        <Div className="cs-iconbox_subtitle">
                            {t('content.identity.content.desc')}
                        </Div>
                        <Spacing lg='50' md='30'/>
                        <Div className="row">
                            <Div className="col-lg-6">
                                <ServicesFeatures btnText={t('content.identity.content.title1')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.identity.content.title2')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.identity.content.title3')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.identity.content.title4')} variant='cs-type2'/>
                                <Spacing lg='0' md='10'/>
                            </Div>
                            <Div className="col-lg-6">
                                <ServicesFeatures btnText={t('content.identity.content.title5')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.identity.content.title6')} variant='cs-type2'/>
                                <Spacing lg='20' md='10'/>
                                <ServicesFeatures btnText={t('content.identity.content.title7')} variant='cs-type2'/>
                                <Spacing lg='0' md='10'/>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
            <Spacing lg='150' md='80'/>

            <ContactCta />
        </>
    )
}

export default withTranslation('common')(Identity);