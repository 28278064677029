import React, {useEffect} from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import VideoModal from '../VideoModal';
import {pageTitle} from '../../helper';
import {withTranslation} from "react-i18next";

function Home({t}) {
    pageTitle(t('menu.home'));

    // Hero Social Links
    const heroSocialLinks = [
        {
            name: 'Youtube',
            links: 'https://www.youtube.com/@cornerofart6037',
        },
        {
            name: 'Twitter',
            links: 'https://twitter.com/cornerofart',
        },
    ];

    // FunFact Data
    const funfaceData = [
        {
            title: t('fact.funfaceData.title1'),
            factNumber: '40K',
        },
        {
            title: t('fact.funfaceData.title2'),
            factNumber: '50K',
        },
        {
            title: t('fact.funfaceData.title3'),
            factNumber: '245',
        },
        {
            title: t('fact.funfaceData.title4'),
            factNumber: '550',
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* Start Hero Section */}
            <Hero
                // title="Corner Of ART <br/> entertainment"
                subtitle={t('header.subtitle')}
                btnText={t('header.btnText')}
                btnLink="/about"
                scrollDownId="#service"
                socialLinksHeading={t('header.socialLinksHeading')}
                heroSocialLinks={heroSocialLinks}
                bgImageUrl="/images/hero_bg_2.jpeg"
                logoImage="/images/logoBg.svg"
            />
            {/* End Hero Section */}

            {/* Start FunFact Section */}
            <div className="container">
                <FunFact
                    variant="cs-type1"
                    title={t('fact.title')}
                    subtitle={t('fact.subtitle')}
                    data={funfaceData}
                />
            </div>
            {/* End FunFact Section */}

            {/* Start Service Section */}
            <Spacing lg="150" md="80"/>
            <Div id="service">
                <Div className="container">
                    <Div className="row">
                        <Div className="col-xl-4">
                            <SectionHeading
                                title={t('service.title')}
                                subtitle={t('service.subtitle')}
                            />
                            <Spacing lg='30' md='30'/>
                            <p> {t('service.description')} </p>
                            <Spacing lg='90' md='45'/>
                        </Div>
                        <Div className='col-xl-8'>
                            <Div className='row'>
                                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                                <Div className='col-lg-3 col-sm-6'>
                                    <Card
                                        title={t('service.serviceData.title1')}
                                        link="/service/commercial-advertising"
                                        src="/images/service_1.jpeg"
                                        alt={t('service.serviceData.title1')}
                                    />
                                    <Spacing lg='0' md='30'/>
                                </Div>
                                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                                <Div className='col-lg-3 col-sm-6'>
                                    <Card
                                        title={t('service.serviceData.title2')}
                                        link="/service/documentary"
                                        src="/images/service_2.jpg"
                                        alt={t('service.serviceData.title2')}
                                    />
                                    <Spacing lg='0' md='30'/>
                                </Div>
                                <Div className='col-lg-3 col-sm-6'>
                                    <Card
                                        title={t('service.serviceData.title3')}
                                        link="/service/animation"
                                        src="/images/service_3.jpg"
                                        alt={t('service.serviceData.title3')}
                                    />
                                    <Spacing lg='0' md='30'/>
                                </Div>
                                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                                <Div className='col-lg-3 col-sm-6'>
                                    <Card
                                        title={t('service.serviceData.title4')}
                                        link="/service/identity"
                                        src="/images/service_4.jpg"
                                        alt={t('service.serviceData.title4')}
                                    />
                                    <Spacing lg='0' md='30'/>
                                </Div>
                                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                                <Div className='col-lg-3 col-sm-6'>
                                    <Card
                                        title={t('service.serviceData.title5')}
                                        link='/service/infographic'
                                        src='/images/service_5.jpeg'
                                        alt={t('service.serviceData.title5')}
                                    />
                                    <Spacing lg='0' md='30'/>
                                </Div>
                                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                                <Div className='col-lg-3 col-sm-6'>
                                    <Card
                                        title={t('service.serviceData.title6')}
                                        link='/service/corporate-profile'
                                        src='/images/service_6.jpeg'
                                        alt={t('service.serviceData.title6')}
                                    />
                                    <Spacing lg='0' md='30'/>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
            {/* End Service Section */}

            {/* Start Video Block Section */}
            <Spacing lg="130" md="70"/>
            <Div className="container">
                <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
                    {t('video.subtitle')}
                </h2>
                <Spacing lg="70" md="70"/>
                <VideoModal
                    videoSrc="https://www.youtube.com/watch?v=wl9mG2xGEUM"
                    bgUrl="/images/a1OTstfD_4x.jpg"
                />
            </Div>
            {/* End Video Block Section */}

            {/* Start Portfolio Section */}
            {/*<Spacing lg="150" md="50"/>
            <Div>
                <Div className="container">
                    <SectionHeading
                        title="Portfolio to explore"
                        subtitle="Latest Projects"
                        variant="cs-style1 text-center"
                    />
                    <Spacing lg="90" md="45"/>
                </Div>
                <PortfolioSlider/>
            </Div>*/}
            {/* End Portfolio Section */}

            {/* Start Testimonial Section */}
           {/* <Spacing lg="150" md="80"/>
            <TestimonialSlider/>*/}
            {/* End Testimonial Section */}


            {/* Start MovingText Section */}
            <Spacing lg="125" md="70"/>
            <MovingText text={t('partner.title')}/>
            <Spacing lg="105" md="70"/>
            {/* End MovingText Section */}

            {/* Start LogoList Section */}
            <Div className="container">
                <LogoList/>
            </Div>
            <Spacing lg="150" md="80"/>
            {/* End LogoList Section */}

            {/* Start CTA Section */}
            <Div className="container">
                <Cta
                    title={t('contactSection.title')}
                    btnText={t('contactSection.btnText')}
                    btnLink="/contact"
                    bgSrc="/images/cta_bg.jpeg"
                />
            </Div>
            {/* End CTA Section */}
        </>
    );
}

export default withTranslation('common')(Home);