import Div from "../Div";
import Cta from "../Cta";
import React from "react";
import {withTranslation} from "react-i18next";


function ContactCta({t}) {
    return (
        <Div className="container">
            <Cta
                title={t('contactSection.title')}
                btnText={t('contactSection.btnText')}
                btnLink='/contact'
                bgSrc='/images/cta_bg.jpeg'
            />
        </Div>
    );
}

export default withTranslation('common')(ContactCta);