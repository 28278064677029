import React from 'react';
import { Icon } from '@iconify/react';

export default function Index({ btnText, variant, icon }) {
    return (
        <div
            className={variant ? `cs-text_btn ${variant}` : 'cs-text_btn'}
        >
            <>
                <span>{btnText}</span>
                {icon ? icon : <Icon icon="bi:arrow-right" />}
            </>
        </div>
    );
}
