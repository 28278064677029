import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import SocialWidget from '../Widget/SocialWidget';
import './header.scss';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import Div from '../Div';
import DropDown from './DropDown';
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

function  Header({ variant, t }) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);


  return (
      <>
        <header
            className={`cs-site_header cs-style1 text-uppercase ${
                variant ? variant : ''
            } cs-sticky_header ${isSticky ? 'cs-sticky_header_active' : ''}`}
        >
          <Div className="cs-main_header">
            <Div className="container">
              <Div className="cs-main_header_in">
                <Div className="cs-main_header_left">
                  <Link className="cs-site_branding" to="/">
                    <img src="/images/logo.svg" alt="Logo" />
                  </Link>
                </Div>
                <Div className="cs-main_header_center">
                  <Div className="cs-nav cs-primary_font cs-medium">
                    <ul
                        className={i18next.language === "ar" ? "cs-nav_list" : "cs-nav_list"}
                        style={{ display: `${mobileToggle ? 'block' : 'none'}` }}
                    >
                      <li>
                        <NavLink to="/" onClick={() => setMobileToggle(false)} end>
                          {t('menu.home')}
                        </NavLink>

                      </li>
                      <li>
                        <NavLink
                            to="about"
                            onClick={() => setMobileToggle(false)}
                        >
                          {t('menu.about')}
                          {/*About*/}
                        </NavLink>
                      </li>
                      <li className="menu-item-has-children">
                        <a href="#"
                            onClick={() => {
                              setMobileToggle(false);
                              setToggle(!toggle);
                            }}
                        >
                          {t('menu.services.main')}
                        </a>
                        <DropDown toggle={toggle}>
                          <ul>
                            <li>
                              <Link
                                  to="/service/animation"
                                  onClick={() => setMobileToggle(false)}
                              >
                                {t('menu.services.sub.Animation')}

                              </Link>
                            </li>
                            <li>
                              <Link
                                  to="/service/corporate-profile"
                                  onClick={() => setMobileToggle(false)}
                              >
                                {t('menu.services.sub.corporate profile')}

                              </Link>
                            </li>
                            <li>
                              <Link
                                  to="/service/commercial-advertising"
                                  onClick={() => setMobileToggle(false)}
                              >
                                {t('menu.services.sub.commercial advertising')}

                              </Link>
                            </li>
                            <li>
                              <Link
                                  to="/service/documentary"
                                  onClick={() => setMobileToggle(false)}
                              >
                                {t('menu.services.sub.documentary')}

                              </Link>
                            </li>
                            <li>
                              <Link
                                  to="/service/identity"
                                  onClick={() => setMobileToggle(false)}
                              >
                                {t('menu.services.sub.identity')}

                              </Link>
                            </li>

                            <li>
                              <Link
                                  to="/service/infographic"
                                  onClick={() => setMobileToggle(false)}
                              >
                                {t('menu.services.sub.infographic')}

                              </Link>
                            </li>
                          </ul>
                        </DropDown>
                      </li>
                      <li>
                        <NavLink
                            to="contact"
                            onClick={() => setMobileToggle(false)}
                        >
                          {t('menu.contact')}
                        </NavLink>
                      </li>

                      <li>
                        <a
                            href="#"
                            onClick={() => {
                              setMobileToggle(false);
                              if (i18next.language === 'ar') {
                                i18next.changeLanguage('en')
                                document.documentElement.lang = 'en';
                                document.documentElement.dir = 'ltr';
                                sessionStorage.setItem('lang','en');
                              } else {
                                i18next.changeLanguage('ar');
                                document.documentElement.lang = 'ar';
                                document.documentElement.dir = 'rtl';
                                sessionStorage.setItem('lang','ar');
                              }
                            }}
                        >
                          {t('menu.lang')}
                        </a>
                      </li>
                    </ul>
                    <span
                        className={
                          mobileToggle
                              ? 'cs-munu_toggle cs-toggle_active'
                              : 'cs-munu_toggle'
                        }
                        onClick={() => setMobileToggle(!mobileToggle)}
                    >
                    <span></span>
                  </span>

                  </Div>
                </Div>
                <Div className="cs-main_header_right">
                  <Div className="cs-toolbox">
                  <span
                      className="cs-icon_btn"
                      onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                  >
                    {/*"cs-icon_btn_in"*/}
                    <span className="cs-icon_btn_in">
                      <span />
                      <span />
                      <span />
                      <span />
                    </span>
                  </span>
                  </Div>

                </Div>

              </Div>
            </Div>

          </Div>
        </header>

        <Div
            className={
              sideHeaderToggle ? 'cs-side_header active' : 'cs-side_header'
            }
        >
          <button
              className="cs-close"
              onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
          />
          <Div
              className="cs-side_header_overlay"
              onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
          />
          <Div className="cs-side_header_in">
            <Div className="cs-side_header_shape" />
            <Link className="cs-site_branding" to="/">
              <img src="/images/logo.svg" alt="Logo" />
            </Link>
            <Div className="cs-side_header_box">
              <h2 className="cs-side_header_heading">


              <Trans i18nKey="header.text">
                {t('header.text')}
              </Trans>

              </h2>
            </Div>
            <Div className="cs-side_header_box">
              <ContactInfoWidget title={t('menu.contact')} withIcon />
            </Div>

            <Div className="cs-side_header_box">
              <SocialWidget />
            </Div>
          </Div>
        </Div>
      </>
  );
}

export default withTranslation('common')(Header);